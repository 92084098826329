<template>
    <div>
        <div class="row bg-white">
            <div class="col-12">
                <span>Title</span>
                <b-form-input v-model="form.title"/>
            </div>
            <div class="col-12pt-4">
                <span>Description</span>
                <b-form-textarea
                    id="textarea"
                    v-model="form.description"
                    placeholder="Enter description..."
                    rows="6"
                    max-rows="9"
                    ></b-form-textarea>
            </div>
            <div class="col-12 pt-4 row">
                <span>Priority level</span>
                <div class="row">
                    <div class="col-12">
                        <b-badge @click="form.level = 'Low'" class="click mr-3" :variant="form.level == 'Low' ? 'primary' : 'light'">Low</b-badge>
                        <b-badge @click="form.level = 'Medium'" class="click mr-3" :variant="form.level == 'Medium' ? 'primary' : 'light'">Medium</b-badge>
                        <b-badge @click="form.level = 'Hight'" class="click" :variant="form.level == 'Hight' ? 'primary' : 'light'">Hight</b-badge>
                    </div>
                </div>
            </div>
            <div class="col-12 pt-4">
                <span>Upload image</span>
                <input
                type="file"
                class="form-control rounded-xs"
                ref="image"
                placeholder="Your Password"
                @change="changeFiles"
                />
            </div>
            <div class="text-right mt-3">
                <b-button variant="secondary" class="mr-3" @click="$emit('close')">
                    Cancel
                </b-button>
                <ButtonAction :loading="loading" loadingTx="Creating membership" title="Create ticket" @click="onSubmit" v-if="!membership"/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: ['membership'],
    data () {
        return {
            form: {
                title: null,
                description: null,
                level: null,
                image: null
            },
            loading: false
        }
    },
    created () {
        if(this.membership) {
            this.form = this.membership
        }
    },
    methods: {
        ...mapActions('support', ['storeSupport']),
        changeFiles(){
            this.form.image = this.$refs.image.files[0]
        },
        onSubmit () {
            this.loading = true
            const formData = new FormData()
                formData.append('title', this.form.title)
                formData.append('image', this.form.image)
                formData.append('description', this.form.description)
                formData.append('level', this.form.level)
            
            this.storeSupport(formData).then(() => {
                this.loading = false
                openNotification()
                this.$emit('close')
            })
        }
    }
}
</script>