<template>
   <div>
      <b-card>
         <b-card-img :src="'https://myelv.apimastercode.com/uploads/supports/' + support.image " alt="Image" class="rounded-0 click" />
         <div class="row">
            <div class="col-12">
                <b-badge variant="primary" pill>{{ support.level }}</b-badge>
            </div>
            <div class="col-12 par mt-3 mb-3">
               {{ support.title }}
            </div>
            <p>
                {{ support.description }}
            </p>
            <div class="col-6">
                Status
            </div>
            <div class="col-6 text-right">
                {{ formData(support.created_at) }} <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.6667 1.33301V3.99967" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.33333 1.33301V3.99967" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 6.00033H14" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6667 2.66699H3.33333C2.59667 2.66699 2 3.26366 2 4.00033V12.667C2 13.4037 2.59667 14.0003 3.33333 14.0003H12.6667C13.4033 14.0003 14 13.4037 14 12.667V4.00033C14 3.26366 13.4033 2.66699 12.6667 2.66699Z" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.67532 8.486C4.58332 8.486 4.50865 8.56067 4.50932 8.65267C4.50932 8.74467 4.58399 8.81934 4.67599 8.81934C4.76799 8.81934 4.84265 8.74467 4.84265 8.65267C4.84265 8.56067 4.76799 8.486 4.67532 8.486" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.00869 8.486C7.91669 8.486 7.84203 8.56067 7.84269 8.65267C7.84269 8.74467 7.91736 8.81934 8.00936 8.81934C8.10136 8.81934 8.17603 8.74467 8.17603 8.65267C8.17603 8.56067 8.10136 8.486 8.00869 8.486" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.3421 8.486C11.2501 8.486 11.1754 8.56067 11.1761 8.65267C11.1761 8.74467 11.2507 8.81934 11.3427 8.81934C11.4347 8.81934 11.5094 8.74467 11.5094 8.65267C11.5094 8.56067 11.4347 8.486 11.3421 8.486" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.67532 11.152C4.58332 11.152 4.50865 11.2267 4.50932 11.3187C4.50932 11.4107 4.58399 11.4854 4.67599 11.4854C4.76799 11.4854 4.84265 11.4107 4.84265 11.3187C4.84265 11.2267 4.76799 11.152 4.67532 11.152" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.00869 11.152C7.91669 11.152 7.84203 11.2267 7.84269 11.3187C7.84269 11.4107 7.91736 11.4854 8.00936 11.4854C8.10136 11.4854 8.17603 11.4107 8.17603 11.3187C8.17603 11.2267 8.10136 11.152 8.00869 11.152" stroke="#00FFFF" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

            </div>
         </div>
         
      </b-card>
   </div>
</template>
<script>
import moment from 'moment'
export default {
   props: ['support'],
   data() {
      return {
         openBig: false
      }
   },
   methods: {
    formData(data) {
        return moment(data).format('Y-MM-DD')
    }
   }
}
</script>
<style scoped>
    .par{
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
    }
    .type {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }
    .income{
        color: #0EC564;
    }
    .outcome{
        color: #FF4E78;
    }
    .title{
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .detail{
        font-weight: 200;
        font-size: 14px;
        line-height: 24px;
    }
</style>