<template>
    <div>
        <div class="text-right">
            <b-button variant="primary" size="lg" @click="modalCreate = true">
                Create case
            </b-button>
        </div>
        <div v-if="loading == false">
            <div class="row" v-if="supports.length > 0">
                <div class="col-12 col-sm-6 col-md-3" v-for="support in supports" :key="support.id">
                    <CardSupport :support="support" />
                </div>
            </div>
            <Empty text="No support cases actives" v-else />
        </div>
        <Loading v-else/>

        <b-modal centered hide-footer title="New ticket" v-model="modalCreate">
            <Form @close="modalCreate = false" />
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CardSupport from './CardSupport.vue'
import Form from './Form.vue'
export default {
    data () {
        return {
            modalCreate: false,
            loading: false
        }
    },
    components: {
        CardSupport,
        Form
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('support', ['getSupports']),
        getData() {
            this.loading = true
            this.getSupports().then(() => {
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('support', ['supports'])
    }
}
</script>